/** @jsxImportSource @emotion/react */
import {
  Box,
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
} from '@mui/material'
import produce from 'immer'
import _, { noop, remove, sum, uniqueId } from 'lodash'
import React, { useEffect } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useRecoilState } from 'recoil'
import { stateAtom } from '../data'
import { HotButton } from './HotButton'
import { celebrationKeys } from './Judging'
import { NumberField } from './NumberField'

const JudgeCard = ({ score, disabled }: { score: number; disabled?: boolean }) => {
  const theme = useTheme()
  return (
    <span
      css={{
        display: 'inline-block',
        textAlign: 'center',
        backgroundColor: 'white',
        color: 'black',
        width: '1.5em',
        lineHeight: '1.5',
        border: '1px solid black',
        fontWeight: 'bold',
        opacity: disabled ? theme.palette.action.disabledOpacity : 1,
      }}
    >
      {score}
    </span>
  )
}

export const ScoreboardJudgingControls = (): JSX.Element => {
  const [state, setState] = useRecoilState(stateAtom)

  const isJudging = state.left.judging === 'active' || state.right.judging === 'active'

  const makeHandleJudgeScore = (team: 'left' | 'right', score: number) => () => {
    setState(
      produce((draft) => {
        draft.presenting.judging = true
        draft.left.judging = team === 'left' ? 'active' : 'disabled'
        draft.right.judging = team === 'right' ? 'active' : 'disabled'
        draft.judging.scores.push(score)
        if (draft.judging.scores[0] === 5 && draft.judging.scores[1] === 5 && draft.judging.scores[2] === 5)
          draft.judging.celebration = true
      })
    )
  }

  const handleApplyJudgeScores = () => {
    let scoringLeft: boolean
    setState(
      produce((draft) => {
        const totalScore = sum(draft.judging.scores) - draft.penalties.timer - draft.penalties.content
        scoringLeft = draft.left.judging === 'active'
        if (scoringLeft) {
          draft.left.score += totalScore
        } else {
          draft.right.score += totalScore
        }
        draft.judging.applying = true
        draft.left.judging = 'disabled'
        draft.right.judging = 'disabled'
        draft.presenting.judging = false
        if (draft.judging.celebration) {
          draft.celebration.selectedIndex += 1
          if (draft.celebration.selectedIndex > celebrationKeys.length - 1) draft.celebration.selectedIndex = 0
        }
      })
    )
    window.setTimeout(() => {
      setState(
        produce((draft) => {
          if (scoringLeft) {
            draft.right.judging = 'enabled'
          } else {
            draft.left.judging = 'enabled'
          }
          draft.judging.celebration = false
          draft.judging.applying = false
          draft.penalties.timer = 0
          draft.penalties.content = 0
          draft.judging.scores = []
        })
      )
    }, 200) // animation duration
  }

  const handleRemoveJudgeScore = () => {
    setState(
      produce((draft) => {
        draft.judging.celebration = false
        draft.judging.scores.length -= 1
      })
    )
  }

  const canJudge = {
    left: state.left.judging !== 'disabled' && state.judging.scores.length < 3,
    right: state.right.judging !== 'disabled' && state.judging.scores.length < 3,
  }

  // RENDER

  return (
    <>
      <Box sx={{ gridArea: 'judgingButton' }}>
        <HotButton
          keyCode="j"
          variant={state.presenting.judging ? 'contained' : 'outlined'}
          onClick={() => {
            setState(
              produce((draft) => {
                draft.presenting.judging = !draft.presenting.judging
              })
            )
          }}
        >
          Judging
        </HotButton>
      </Box>
      <Stack
        flex={1}
        // alignItems="center"
        css={{ gridArea: 'cardsLeft' }}
      >
        <Stack direction="row" spacing="2px">
          <HotButton keyCode="1" onClick={makeHandleJudgeScore('left', 1)} disabled={!canJudge.left}>
            <JudgeCard score={1} disabled={!canJudge.left} />
          </HotButton>
          <HotButton keyCode="2" onClick={makeHandleJudgeScore('left', 2)} disabled={!canJudge.left}>
            <JudgeCard score={2} disabled={!canJudge.left} />
          </HotButton>
          <HotButton keyCode="3" onClick={makeHandleJudgeScore('left', 3)} disabled={!canJudge.left}>
            <JudgeCard score={3} disabled={!canJudge.left} />
          </HotButton>
          <HotButton keyCode="4" onClick={makeHandleJudgeScore('left', 4)} disabled={!canJudge.left}>
            <JudgeCard score={4} disabled={!canJudge.left} />
          </HotButton>
          <HotButton keyCode="5" onClick={makeHandleJudgeScore('left', 5)} disabled={!canJudge.left}>
            <JudgeCard score={5} disabled={!canJudge.left} />
          </HotButton>
        </Stack>
      </Stack>
      <Box css={{ gridArea: 'enable' }}>
        <Button
          onClick={() => {
            setState(
              produce((draft) => {
                draft.left.judging = 'enabled'
                draft.right.judging = 'enabled'
              })
            )
          }}
          disabled={
            state.judging.scores.length > 0 || (state.left.judging === 'enabled' && state.right.judging === 'enabled')
          }
        >
          Enable Both
        </Button>
      </Box>
      <Box css={{ gridArea: 'celebration' }}>
        <FormControl>
          <InputLabel size="small">Celebration</InputLabel>
          <Select
            value={state.celebration.selectedIndex}
            label="Celebration"
            onChange={(e) =>
              setState(
                produce((draft) => {
                  draft.celebration.selectedIndex = Number(e.target.value)
                })
              )
            }
          >
            {celebrationKeys.map((key, index) => (
              <MenuItem key={key} value={index}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Stack
        flex={1}
        // alignItems="center"
        css={{ gridArea: 'cardsRight' }}
      >
        <Stack direction="row" spacing="2px">
          <HotButton keyCode="6" onClick={makeHandleJudgeScore('right', 1)} disabled={!canJudge.right}>
            <JudgeCard score={1} disabled={!canJudge.right} />
          </HotButton>
          <HotButton keyCode="7" onClick={makeHandleJudgeScore('right', 2)} disabled={!canJudge.right}>
            <JudgeCard score={2} disabled={!canJudge.right} />
          </HotButton>
          <HotButton keyCode="8" onClick={makeHandleJudgeScore('right', 3)} disabled={!canJudge.right}>
            <JudgeCard score={3} disabled={!canJudge.right} />
          </HotButton>
          <HotButton keyCode="9" onClick={makeHandleJudgeScore('right', 4)} disabled={!canJudge.right}>
            <JudgeCard score={4} disabled={!canJudge.right} />
          </HotButton>
          <HotButton keyCode="0" onClick={makeHandleJudgeScore('right', 5)} disabled={!canJudge.right}>
            <JudgeCard score={5} disabled={!canJudge.right} />
          </HotButton>
        </Stack>
      </Stack>
      {state.judging.scores.length > 0 && !state.judging.applying && (
        <Stack
          flex={1}
          direction="row"
          alignItems="center"
          // justifyContent="center"
          css={{ gridArea: state.left.judging === 'active' ? 'judgesLeft' : 'judgesRight' }}
        >
          <Box sx={{ width: '5em' }}>
            {state.judging.scores.map((judgeScore, index) => (
              <JudgeCard key={index} score={judgeScore} />
            ))}
          </Box>
          <Box>
            <HotButton keyCode="enter" onClick={handleApplyJudgeScores} disabled={state.judging.scores.length < 3}>
              Apply
            </HotButton>
          </Box>
          <Box>
            <HotButton
              keyCode="backspace"
              onClick={handleRemoveJudgeScore}
              disabled={state.judging.scores.length === 0}
            >
              Delete
            </HotButton>
          </Box>
        </Stack>
      )}
    </>
  )
}
