import { defaultState, stateAtom } from './state'
import { TypingState } from './typing'

export type ChannelMessage =
  | { type: 'ping'; payload: undefined } // sent by control to detect presentation
  | { type: 'refreshSlides'; payload: undefined } // sent by control to instruct presentation to reload slides
  | { type: 'fields'; payload: typeof defaultState } // sent by control to update presentation
  | { type: 'presentationDimensions'; payload: { height: number; width: number } } // sent by presentation on mount or in response to ping
  | { type: 'typingState'; payload: TypingState } // sent by control to set lines

export const channel = new BroadcastChannel('main')

export const broadcast = (message: ChannelMessage) => {
  // console.log('broadcasting', message)
  channel.postMessage(message)
}
