/** @jsxImportSource @emotion/react */
import { useTheme } from '@mui/material'
import React, { useEffect } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

export interface KeyHintProps {
  keyCode: string
  hint?: string
  onPress: () => void
  disabled?: boolean
}

export const KeyHint = ({ keyCode, onPress, disabled, hint }: KeyHintProps): JSX.Element => {
  useHotkeys(
    keyCode,
    (e) => {
      if (disabled) return
      e.preventDefault()
      onPress()
    },
    [disabled]
  )

  const theme = useTheme()

  return (
    <span
      css={{
        display: 'inline-block',
        marginLeft: '0.3em',
        padding: '0.1em 0.3em',
        background: disabled ? 'transparent' : 'lightgrey',
        color: disabled ? theme.palette.text.disabled : 'black',
        border: disabled ? `1px solid ${theme.palette.divider}` : '1px solid darkgrey',
        borderRadius: '0.3em',
        minWidth: '1.5em',
        fontSize: '75%',
      }}
    >
      {keyCode.toUpperCase()}
    </span>
  )
}
