/** @jsxImportSource @emotion/react */
import React from 'react'
import { useRecoilValue } from 'recoil'
import { defaultPresentation } from '../config'
import { presentationAtom } from '../data'
import { useViewSize } from '../lib/useViewSize'

export const Preview = (): JSX.Element => {
  const { connected, ...presentationDimensions } = useRecoilValue(presentationAtom)

  const { width, height } = connected ? presentationDimensions : defaultPresentation
  const aspectRatio = width / height

  const { vh } = useViewSize()
  const iFrameHeight = vh(100)

  const iFrameScale = iFrameHeight / height

  return (
    <div
      css={{
        zIndex: 100,
        pointerEvents: 'none',
        height: iFrameHeight,
        width: iFrameHeight * aspectRatio,
        background: 'grey',
      }}
    >
      <iframe
        css={{
          width,
          height,
          transform: `scale(${iFrameScale})`,
          transformOrigin: '0 0',
        }}
        title="preview"
        tabIndex={-1}
        src={`${window.location.pathname}#preview`}
      />
    </div>
  )
}
