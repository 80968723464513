/** @jsxImportSource @emotion/react */
import { Card, Stack, TextField, Divider, Box, useTheme } from '@mui/material'
import produce from 'immer'
import { noop } from 'lodash'
import React from 'react'
import { Color } from 'react-input-color'
import { useRecoilState } from 'recoil'
import { stateAtom } from '../data'
import { HotButton } from './HotButton'
import { NumberField } from './NumberField'
import ColorInput from 'react-input-color'
import { ScoreboardJudgingControls } from './ScoreboardJudgingControls'
import { ScoreboardPenaltiesControls } from './ScoreboardPenaltiesControls'

export const ScoreboardControls = (): JSX.Element => {
  const [state, setState] = useRecoilState(stateAtom)

  const theme = useTheme()

  return (
    <Card
      css={{
        display: 'grid',
        gridTemplateAreas: `
"scoreboardButton teamLeft swap teamRight ."
"divider1 divider1 divider1 divider1 divider1"
"judgingButton cardsLeft enable cardsRight ."
". judgesLeft celebration judgesRight ."
  `,
        gridTemplateColumns: 'auto 1fr auto 1fr auto',
        gridTemplateRows: 'auto auto auto minmax(2.5rem, auto)',
        gap: theme.spacing(1),
        flexShrink: 0,
      }}
      sx={{
        p: 1,
        m: 1,
      }}
    >
      <Stack sx={{ gridArea: 'scoreboardButton' }}>
        <HotButton
          keyCode="s"
          variant={state.presenting.scoreboard ? 'contained' : 'outlined'}
          onClick={() => {
            setState(
              produce((draft) => {
                draft.presenting.scoreboard = !draft.presenting.scoreboard
                draft.scoreboard.finale = false
              })
            )
          }}
        >
          Scoreboard
        </HotButton>
      </Stack>
      <Stack direction="row" css={{ gridArea: 'teamLeft' }}>
        <Stack flex={1}>
          <TextField
            label="Name"
            inputProps={{ sx: { fontWeight: 'bold' } }}
            value={state.left.name}
            onChange={(e) =>
              setState(
                produce((draft) => {
                  draft.left.name = e.target.value
                })
              )
            }
          />
          <TextField
            label="Players"
            value={state.left.notes}
            onChange={(e) =>
              setState(
                produce((draft) => {
                  draft.left.notes = e.target.value
                })
              )
            }
          />
          <Stack direction="row">
            <NumberField
              label="Size"
              sx={{ width: '5em' }}
              value={state.left.fontSize}
              onChange={(e) => {
                setState(
                  produce((draft) => {
                    draft.left.fontSize = Number(e.target.value)
                  })
                )
              }}
            />
            <ColorInput
              initialValue={state.left.backgroundColor}
              onChange={(e) =>
                setState(
                  produce((draft) => {
                    draft.left.backgroundColor = e.hex
                  })
                )
              }
              placement="right"
            />
          </Stack>
        </Stack>
        <Stack>
          <NumberField
            label="Score"
            value={state.left.score}
            onChange={(e) => {
              setState(
                produce((draft) => {
                  draft.left.score = Number(e.target.value)
                })
              )
            }}
            sx={{ width: '5em' }}
          />
          <HotButton
            keyCode="a"
            variant={state.presenting.introducingLeft ? 'contained' : 'outlined'}
            onClick={() => {
              setState(
                produce((draft) => {
                  draft.presenting.introducingRight = false
                  draft.presenting.introducingLeft = !draft.presenting.introducingLeft
                })
              )
            }}
          >
            Intro
          </HotButton>
        </Stack>
      </Stack>
      <Stack css={{ gridArea: 'swap' }} justifyContent="center">
        <HotButton
          keyCode="x"
          onClick={() => {
            setState(
              produce((draft) => {
                ;[draft.left, draft.right] = [draft.right, draft.left]
              })
            )
          }}
        >
          Swap
        </HotButton>
      </Stack>
      <Stack direction="row" css={{ gridArea: 'teamRight' }}>
        <Stack flex={1}>
          <TextField
            label="Name"
            inputProps={{ sx: { fontWeight: 'bold' } }}
            value={state.right.name}
            onChange={(e) =>
              setState(
                produce((draft) => {
                  draft.right.name = e.target.value
                })
              )
            }
          />
          <TextField
            label="Players"
            value={state.right.notes}
            onChange={(e) =>
              setState(
                produce((draft) => {
                  draft.right.notes = e.target.value
                })
              )
            }
          />
          <Stack direction="row">
            <NumberField
              label="Size"
              sx={{ width: '5em' }}
              value={state.right.fontSize}
              onChange={(e) => {
                setState(
                  produce((draft) => {
                    draft.right.fontSize = Number(e.target.value)
                  })
                )
              }}
            />
            <ColorInput
              initialValue={state.right.backgroundColor}
              onChange={(e) =>
                setState(
                  produce((draft) => {
                    draft.right.backgroundColor = e.hex
                  })
                )
              }
              placement="right"
            />
          </Stack>
        </Stack>
        <Stack>
          <NumberField
            label="Score"
            value={state.right.score}
            onChange={(e) => {
              setState(
                produce((draft) => {
                  draft.right.score = Number(e.target.value)
                })
              )
            }}
            sx={{ width: '5em' }}
          />
          <HotButton
            keyCode="b"
            variant={state.presenting.introducingRight ? 'contained' : 'outlined'}
            onClick={() => {
              setState(
                produce((draft) => {
                  draft.presenting.introducingLeft = false
                  draft.presenting.introducingRight = !draft.presenting.introducingRight
                })
              )
            }}
          >
            Intro
          </HotButton>
        </Stack>
      </Stack>
      <Box>
        <HotButton
          keyCode="f"
          disabled={state.presenting.scoreboard && !state.scoreboard.finale}
          variant={state.scoreboard.finale ? 'contained' : 'outlined'}
          onClick={() => {
            setState(
              produce((draft) => {
                if (draft.presenting.scoreboard) {
                  draft.presenting.scoreboard = false
                  draft.scoreboard.finale = false
                } else {
                  draft.presenting.scoreboard = true
                  draft.scoreboard.finale = true
                }
              })
            )
          }}
        >
          Finale
        </HotButton>
      </Box>
      <Box sx={{ gridArea: 'divider1', m: 1 }}>
        <Divider />
      </Box>
      {/* <ScoreboardPenaltiesControls /> */}
      <ScoreboardJudgingControls />
    </Card>
  )
}
