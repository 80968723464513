/** @jsxImportSource @emotion/react */
import React from 'react'
import { RecoilRoot } from 'recoil'
import { Main } from './components/Main'


export interface AppProps {}

export const App = (props: AppProps): JSX.Element => {
  return (
    <RecoilRoot>
      <Main />
    </RecoilRoot>
  )
}
