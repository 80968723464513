/** @jsxImportSource @emotion/react */

import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { times } from 'lodash'
import { config } from '../config'
import { TransitionGroup } from 'react-transition-group'
import { zIndex } from './constants'
import { stateAtom } from '../data'
import { useRecoilValue } from 'recoil'
import { gsap } from 'gsap'
import { useViewSize } from '../lib/useViewSize'

interface ScoreCardProps {
  score?: number
  in?: boolean
  onExited?: () => void
}

const AwaitingScoreCard = () => {
  const { vw } = useViewSize()
  return (
    <div
      css={{
        fontFamily: config.numberFontFamily,
        fontSize: vw(config.judgeScoreFontSize),
        background: 'transparent',
        color: 'transparent',
        lineHeight: 1,
        padding: '0.1em',
        margin: '0.1em',
      }}
    >
      0
    </div>
  )
}

const ScoreCard = ({ score, in: inProp, onExited }: ScoreCardProps) => {
  const [opacity, setOpacity] = useState(0)

  const { vw } = useViewSize()

  useEffect(() => {
    if (!inProp && onExited) onExited()
  }, [inProp])

  useEffect(() => {
    if (!score) return
    setOpacity(1)
  }, [score])

  return (
    <div
      className="ScoreCard"
      css={{
        fontFamily: config.numberFontFamily,
        fontSize: vw(config.judgeScoreFontSize),
        background: 'white',
        lineHeight: 1,
        padding: '0.1em',
        margin: '0.1em',
        transition: 'opacity 500ms linear',
        opacity,
        WebkitTextStrokeWidth: vw(0.25),
        WebkitTextStrokeColor: 'white',
        color: 'black',
      }}
    >
      {score}
    </div>
  )
}

export const celebrationKeys = [
  'flames',
  'fireworks',
  'pink heart',
  'puppies',
  // 'halloween'
  'snowflakes',
  // 'red rose',
  'bloom',
] as const

export const Judging = (): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null)
  const state = useRecoilValue(stateAtom)
  const videoRef = useRef<HTMLVideoElement>(null)

  useLayoutEffect(() => {
    const q = gsap.utils.selector(ref.current)
    const tl = gsap.timeline().pause()

    const reset = () => {
      gsap.set(q('.ScoreCard'), { background: 'white', color: 'black' })
      gsap.set(q('video'), { opacity: 0 })
      const videoSource = q('source')[0]

      videoRef.current!.pause()
      videoRef.current!.currentTime = 0

      const startVideo = () => {
        videoRef.current!.play()
      }

      const setVideoSource = (filename: string) => {
        videoSource.setAttribute('src', `https://improv-scoreboard.s3.us-west-2.amazonaws.com/${filename}`)
        videoRef.current!.load()
      }

      const celebrationKey = celebrationKeys[state.celebration.selectedIndex]

      switch (celebrationKey) {
        case 'flames': {
          setVideoSource('flame_wall.mp4')
          tl.to(q('.ScoreCard'), { background: 'transparent', color: '#e3ab05', duration: 1 }, 3.5)
          tl.call(startVideo, undefined, 2)
          tl.to(q('video'), { opacity: 1, duration: 0 }, 2)
          tl.to(q('video'), { opacity: 0, duration: 1 }, 11.5)
          break
        }
        case 'fireworks': {
          setVideoSource('fireworks_1.mp4')
          tl.to(q('.ScoreCard'), { background: 'transparent', color: '#c10101', duration: 2 }, 2)
          tl.call(startVideo, undefined, 2.5)
          tl.to(q('video'), { opacity: 1, duration: 2 }, 2.5)
          tl.to(q('video'), { opacity: 0, duration: 2 }, 14)
          break
        }
        case 'pink heart': {
          setVideoSource('pink_heart.mp4')
          tl.to(q('.ScoreCard'), { background: 'transparent', color: '#e0097f', duration: 2 }, 2)
          tl.call(startVideo, undefined, 2.5)
          tl.to(q('video'), { opacity: 1, duration: 2 }, 2.5)
          break
        }
        case 'puppies': {
          setVideoSource('park_puppies.mp4')
          tl.to(q('.ScoreCard'), { background: 'transparent', color: '#007200', duration: 2 }, 2)
          tl.call(startVideo, undefined, 2.5)
          tl.to(q('video'), { opacity: 1, duration: 2 }, 2)
          tl.to(q('video'), { opacity: 0, duration: 2 }, 17)
          break
        }
        // case 'halloween': {
        //   setVideoSource('halloween_lights.mp4')
        //   tl.to(q('.ScoreCard'), { background: 'transparent', color: '#ffa838', duration: 2 }, 2)
        //   tl.call(startVideo, undefined, 2.5)
        //   tl.to(q('video'), { opacity: 1, duration: 2 }, 2)
        //   break
        // }
        case 'snowflakes': {
          setVideoSource('snowflakes.mp4')
          tl.to(q('.ScoreCard'), { background: 'transparent', color: '#2596be', duration: 2 }, 2)
          tl.call(startVideo, undefined, 2.5)
          tl.to(q('video'), { opacity: 1, duration: 2 }, 2)
          break
        }
        // case 'red rose': {
        //   setVideoSource('red_rose.mp4')
        //   tl.to(q('.ScoreCard'), { background: 'transparent', color: '#bbbbbb', duration: 2 }, 2)
        //   tl.call(startVideo, undefined, 2.5)
        //   tl.to(q('video'), { opacity: 1, duration: 2 }, 2)
        //   break
        // }
        case 'bloom': {
          setVideoSource('bloom.mp4')
          tl.to(q('.ScoreCard'), { background: 'transparent', color: '#36b500', duration: 2 }, 2)
          tl.call(startVideo, undefined, 2.5)
          tl.to(q('video'), { opacity: 1, duration: 2 }, 2)
          tl.to(q('video'), { opacity: 0, duration: 2 }, 15)
          break
        }
      }
    }

    reset()
    if (!state.judging.celebration) return

    tl.play()

    return () => {
      reset()
      tl.kill()
    }
  }, [state.judging.celebration])

  return (
    <div
      ref={ref}
      css={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        background: '#000000aa',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <video
        ref={videoRef}
        muted
        loop
        css={{
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          opacity: 0,
        }}
      >
        <source src="" type="video/mp4" />
      </video>
      <TransitionGroup
        css={{
          zIndex: 1,
          width: '80%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {times(config.judgeCount).map((index) => {
          const score = state.judging.scores[index]
          if (!score) return <AwaitingScoreCard key={index} />
          return <ScoreCard key={index} score={score} />
        })}
      </TransitionGroup>
      {(!!state.penalties.timer || !!state.penalties.content) && (
        <div css={{ marginTop: '1em', display: 'flex', fontSize: `${config.scoringPenaltiesFontSize}vw`, gap: '1em' }}>
          {!!state.penalties.timer && (
            <div css={{ zIndex: 1, background: '#aa0000', color: 'white', padding: '0.25em' }}>
              <strong>-{state.penalties.timer}</strong> FOR OVERTIME
            </div>
          )}
          {!!state.penalties.content && (
            <div css={{ background: '#eeee00', color: 'black', padding: '0.25em' }}>
              <strong>-{state.penalties.content}</strong> FOR CONTENT
            </div>
          )}
        </div>
      )}
    </div>
  )
}
