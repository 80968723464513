/* eslint-disable jsx-a11y/alt-text */
/** @jsxImportSource @emotion/react */
import React from 'react'
import { useRecoilValue } from 'recoil'
import { slidesAtom, stateAtom } from '../data'

export interface SlideProps {}

export const Slide = (props: SlideProps): JSX.Element => {
  const state = useRecoilValue(stateAtom)
  const slides = useRecoilValue(slidesAtom)

  const slide = slides.find((slide) => slide.id === state.slides.selectedId)

  if (!slide) return <div />

  return (
    <div css={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
      <img
        css={{
          objectFit: 'contain',
          width: '100%',
          height: '100%',
        }}
        src={slide.url}
      />
    </div>
  )
}
