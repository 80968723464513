/** @jsxImportSource @emotion/react */
import React, { useLayoutEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { useIsFirstRender } from '../lib/useIsFirstRender'
import { useRecoilValue } from 'recoil'
import { stateAtom } from '../data'
import { useViewSize } from '../lib/useViewSize'

// const ease = CustomEase.create(
//   'custom',
//   'M0,0 C0.016,0.218 0.054,0.378 0.136,0.552 0.258,0.811 0.331,0.868 0.422,0.91 0.474,0.934 0.88,0.974 1,1 '
// )

export interface IntroducingProps {
  in: boolean
  side: 'left' | 'right'
}

export const Introducing = ({ in: inProp, side }: IntroducingProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null)

  const state = useRecoilValue(stateAtom)
  const { name, notes, textColor, backgroundColor, fontFamily, fontSize } = state[side]

  const isFirstRender = useIsFirstRender()
  const { vh } = useViewSize()

  const [nameFrom, notesFrom, everythingTo] = side === 'left' ? ['-100%', '100%', '-100%'] : ['100%', '-100%', '100%']

  const playerNameIntro = (() => {
    const playerNames = notes.split(',')
    if (playerNames.length !== 3) return ''
    return `${playerNames[0]}, ${playerNames[1]}, and ${playerNames[2]} are...`
  })()

  useLayoutEffect(() => {
    if (!ref.current) return
    const q = gsap.utils.selector(ref.current)
    const reset = () => {
      gsap.set(q('.background'), { opacity: 0 })
      gsap.set(q('.name'), { left: nameFrom, top: 0 })
      gsap.set(q('.notes'), { left: notesFrom, top: 0 })
    }
    if (isFirstRender) {
      reset()
      return
    }
    if (inProp) {
      reset()
      const tl = gsap.timeline()
      tl.to(q('.background'), { opacity: 1, duration: 1, ease: 'power2' })
      tl.to(q('.name'), { left: 0, duration: 1, ease: 'power2' }, 0)
      tl.to(q('.notes'), { left: 0, duration: 1, ease: 'power2' }, 0)
    } else {
      const tl = gsap.timeline()
      tl.to(q('.background'), { opacity: 0, duration: 0.75, ease: 'power1.in' }, 0)
      tl.to(q('.name'), { top: everythingTo, duration: 0.75, ease: 'power1.in' }, 0)
      tl.to(q('.notes'), { top: everythingTo, duration: 0.75, ease: 'power1.in' }, 0)
    }
  }, [inProp])

  return (
    <div
      ref={ref}
      css={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <div
        className="background"
        css={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          background: backgroundColor,
        }}
      />
      <div
        className="notes"
        css={{
          color: textColor,
          position: 'relative',
          fontSize: vh(6),
          marginBottom: vh(4),
        }}
      >
        {playerNameIntro}
      </div>
      <div
        className="name"
        css={{
          position: 'relative',
          textAlign: 'center',
          fontFamily,
          fontSize: vh((fontSize / 2) * 1.25), // bigger than scoreboard
          color: textColor,
          lineHeight: 1.1,
          padding: '0 10%',
          boxSizing: 'border-box',
        }}
      >
        {name}
      </div>
    </div>
  )
}
