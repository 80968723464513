import { createTheme } from '@mui/material'

export const theme = createTheme({
  palette: {
    mode: 'dark',
  },
  components: {
    MuiInput: {
      defaultProps: {
        autoComplete: 'off', // this is actually browser auto-fill
      },
    },
    MuiTextField: {
      defaultProps: {
        autoComplete: 'off', // this is actually browser auto-fill
        size: 'small',
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'outlined',
        size: 'medium',
        tabIndex: -1,
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        // size: 'small',
      },
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiToggleButton: {
      defaultProps: {
        size: 'medium',
        tabIndex: -1,
      },
    },
    MuiToggleButtonGroup: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiCard: {
      defaultProps: {
        sx: {
          p: 1,
          m: 1,
        },
      },
    },
    MuiStack: {
      defaultProps: {
        spacing: 1,
      },
    },
  },
})
