/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import { config } from '../config'
import { stateAtom } from '../data'
import { useViewSize } from '../lib/useViewSize'
import { Background } from './Background'
import { Score } from './Score'
import { TeamName } from './TeamName'
import { gsap, Power1, Power2 } from 'gsap'
import { CustomEase } from 'gsap/CustomEase'

gsap.registerPlugin(CustomEase)

export const SCORE_ANIMATION_DURATION_MS = 1500
export const FINALE_ANIMATION_DURATION_MS_1 = 3000
export const FINALE_ANIMATION_DURATION_MS_2 = 1000

const customEase = CustomEase.create(
  'custom',
  'M0,0 C0.144,0.058 0.137,0.124 0.156,0.182 0.224,0.392 0.611,0.832 0.624,0.846 0.676,0.904 0.858,1 1,1 '
)

export const Scoreboard = (): JSX.Element => {
  const [state, setState] = useRecoilState(stateAtom)

  const [leftValue, setLeftValue] = useState(state.left.score)
  const [rightValue, setRightValue] = useState(state.right.score)

  const { vw } = useViewSize()

  useEffect(() => {
    // Left (non-finale)
    ;(() => {
      if (leftValue === state.left.score || state.scoreboard.finale) return

      const startValue = leftValue
      const startTime = Date.now()

      const animate = () => {
        const now = Date.now()
        const absoluteProgress = Math.min(1, (now - startTime) / SCORE_ANIMATION_DURATION_MS)
        const easedProgress = Power2.easeInOut(absoluteProgress)
        setLeftValue(startValue + (state.left.score - startValue) * easedProgress)
        if (absoluteProgress < 1) requestAnimationFrame(animate)
      }
      animate()
    })()
    // Right (non-finale)
    ;(() => {
      if (rightValue === state.right.score || state.scoreboard.finale) return

      const startValue = rightValue
      const startTime = Date.now()

      const animate = () => {
        const now = Date.now()
        const absoluteProgress = Math.min(1, (now - startTime) / SCORE_ANIMATION_DURATION_MS)
        const easedProgress = Power2.easeOut(absoluteProgress)
        setRightValue(startValue + (state.right.score - startValue) * easedProgress)
        if (absoluteProgress < 1) requestAnimationFrame(animate)
      }
      animate()
    })()
    // finale
    ;(() => {
      if (!state.scoreboard.finale) return
      setLeftValue(0)
      setRightValue(0)

      const startTime1 = Date.now()
      let startTime2: number
      const loserScore = Math.min(state.left.score, state.right.score)
      const winnerScore = Math.max(state.left.score, state.right.score)

      const animate3 = () => {
        console.log('animate3')
      }

      const animate2 = () => {
        const now = Date.now()
        const absoluteProgress = Math.min(1, (now - startTime2) / FINALE_ANIMATION_DURATION_MS_2)
        const easedProgress = Power1.easeOut(absoluteProgress)

        const winnerSetter = state.left.score > state.right.score ? setLeftValue : setRightValue
        winnerSetter(loserScore + (winnerScore - loserScore) * easedProgress)
        if (absoluteProgress < 1) {
          requestAnimationFrame(animate2)
        } else {
          animate3()
        }
      }

      const animate1 = () => {
        const now = Date.now()
        const absoluteProgress = Math.min(1, (now - startTime1) / FINALE_ANIMATION_DURATION_MS_1)
        const easedProgress = customEase(absoluteProgress)

        setLeftValue(loserScore * easedProgress)
        setRightValue(loserScore * easedProgress)
        if (absoluteProgress < 1) {
          requestAnimationFrame(animate1)
        } else {
          startTime2 = Date.now()
          animate2()
        }
      }

      window.setTimeout(() => animate1(), 1000)
    })()
  }, [state.left.score, state.right.score, state.scoreboard.finale])

  return (
    <div
      css={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        background: 'grey',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: config.numberFontFamily,
      }}
    >
      <Background
        colorLeft={state.left.backgroundColor}
        colorRight={state.right.backgroundColor}
        colorSeparator="white"
      />
      <div
        css={{
          position: 'absolute',
          top: `${config.nameY}%`,
          left: `${config.nameX}%`,
          height: `${config.nameHeight}%`,
          width: `${config.nameWidth}%`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <TeamName
          css={{ textAlign: 'left', maxWidth: vw(90) }}
          fontFamily={state.left.fontFamily}
          fontSize={state.left.fontSize}
          value={state.left.name}
          color={state.left.textColor}
        />
      </div>
      <div
        css={{
          position: 'absolute',
          top: `${config.scoreY}%`,
          left: `${config.scoreX}%`,
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Score key={state.left.name} css={{ textAlign: 'left' }} value={leftValue} color={state.left.textColor} />
      </div>
      <div
        css={{
          position: 'absolute',
          bottom: `${config.nameY}%`,
          right: `${config.nameX}%`,
          height: `${config.nameHeight}%`,
          width: `${config.nameWidth}%`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <TeamName
          css={{ textAlign: 'right', maxWidth: vw(90) }}
          fontFamily={state.right.fontFamily}
          fontSize={state.right.fontSize}
          value={state.right.name}
          color={state.right.textColor}
        />
      </div>
      <div
        css={{
          position: 'absolute',
          bottom: `${config.scoreY}%`,
          right: `${config.scoreX}%`,
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Score key={state.right.name} css={{ textAlign: 'right' }} value={rightValue} color={state.right.textColor} />
      </div>
    </div>
  )
}
