/** @jsxImportSource @emotion/react */
import { Card, Stack, TextField, ToggleButton, ToggleButtonGroup } from '@mui/material'
import produce from 'immer'
import _, { noop, remove, uniqueId } from 'lodash'
import React, { useEffect } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useRecoilState } from 'recoil'
import { stateAtom } from '../data'
import { Button } from './Button'
import { HotButton } from './HotButton'
import { NumberField } from './NumberField'

export interface TimerControlsProps {}

export const TimerControls = (props: TimerControlsProps): JSX.Element => {
  const [state, setState] = useRecoilState(stateAtom)

  // countdown interval
  useEffect(() => {
    if (!state.timer.running) return
    const interval = window.setInterval(() => {
      setState(
        produce((draft) => {
          draft.timer.seconds -= 1
        })
      )
    }, 1000)
    return () => {
      window.clearInterval(interval)
    }
  }, [state.timer.running])

  // handle zero
  useEffect(() => {
    if (state.timer.seconds > 0 || !state.timer.running) return

    switch (state.timer.mode) {
      case 'overtime': {
        setState(
          produce((draft) => {
            draft.penalties.timer += 1
            draft.timer.seconds = draft.timer.fullSeconds
          })
        )
        break
      }
      case 'game': {
        setState(
          produce((draft) => {
            draft.timer.running = false
          })
        )
        break
      }
    }
  }, [state.timer.seconds])

  // listen for blackout
  useHotkeys('esc', () => {
    setState(
      produce((draft) => {
        draft.timer.running = false
      })
    )
  })

  // RENDER

  return (
    <Card css={{ flexShrink: 0 }}>
      <Stack direction="row">
        <HotButton
          keyCode="t"
          variant={state.presenting.timer ? 'contained' : 'outlined'}
          onClick={() => {
            setState(
              produce((draft) => {
                if (draft.presenting.timer) {
                  draft.presenting.timer = false
                  draft.timer.running = false
                } else {
                  draft.presenting.timer = true
                }
              })
            )
          }}
        >
          Timer
        </HotButton>
        <div>
          <ToggleButtonGroup
            disabled={state.timer.running}
            value={state.timer.mode}
            exclusive
            onChange={(_, newValue: 'overtime' | 'game') => {
              setState(
                produce((draft) => {
                  if (!newValue) return
                  draft.timer.mode = newValue
                })
              )
            }}
          >
            <ToggleButton value="overtime">Overtime</ToggleButton>
            <ToggleButton value="game">Game</ToggleButton>
          </ToggleButtonGroup>
        </div>
        <NumberField
          label="Seconds"
          css={{ width: '5em' }}
          disabled={state.timer.running}
          value={String(state.timer.seconds)}
          onChange={(e) =>
            setState(
              produce((draft) => {
                const asNumber = Number(e.target.value)
                if (isFinite(asNumber) && asNumber >= 0) draft.timer.seconds = asNumber
              })
            )
          }
        />
        <Button
          disabled={state.timer.running}
          onClick={() =>
            setState(
              produce((draft) => {
                draft.timer.seconds = 120
              })
            )
          }
        >
          2:00
        </Button>
        <Button
          disabled={state.timer.running}
          onClick={() =>
            setState(
              produce((draft) => {
                draft.timer.seconds = 60
              })
            )
          }
        >
          1:00
        </Button>
        <Button
          disabled={state.timer.running}
          onClick={() =>
            setState(
              produce((draft) => {
                draft.timer.seconds = 30
              })
            )
          }
        >
          0:30
        </Button>
        <Button
          disabled={state.timer.running}
          onClick={() =>
            setState(
              produce((draft) => {
                draft.timer.seconds = 15
              })
            )
          }
        >
          0:15
        </Button>
        <Button
          disabled={state.timer.running}
          onClick={() =>
            setState(
              produce((draft) => {
                draft.timer.seconds = 7
              })
            )
          }
        >
          0:07
        </Button>
        <NumberField
          label="Overtimes"
          css={{ width: '6em' }}
          value={state.penalties.timer}
          onChange={(e) => {
            setState(
              produce((draft) => {
                draft.penalties.timer = Math.max(Number(e.target.value), 0)
              })
            )
          }}
        />
        {state.timer.mode === 'overtime' ? (
          // overtime mode
          <HotButton
            keyCode="space"
            onClick={() => {
              setState(
                produce((draft) => {
                  if (draft.timer.running) {
                    draft.timer.running = false
                    return
                  }
                  draft.timer.seconds = draft.timer.fullSeconds
                  draft.penalties.timer = 0
                  draft.timer.running = true
                  draft.presenting.timer = true
                })
              )
            }}
          >
            {state.timer.running ? 'Stop Timer' : 'Start Red Flag'}
          </HotButton>
        ) : (
          // game mode
          <HotButton
            disabled={state.timer.seconds === 0}
            keyCode="space"
            onClick={() => {
              setState(
                produce((draft) => {
                  if (draft.timer.running) {
                    draft.timer.running = false
                    return
                  }
                  draft.timer.running = true
                  draft.presenting.timer = true
                })
              )
            }}
          >
            {state.timer.running ? 'Stop Timer' : 'Start Timer'}
          </HotButton>
        )}
      </Stack>
    </Card>
  )
}
