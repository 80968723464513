/** @jsxImportSource @emotion/react */
import { noop } from 'lodash'
import React from 'react'
import { Button, ButtonProps } from './Button'
import { KeyHint } from './KeyHint'

export interface HotButtonProps extends ButtonProps {
  keyCode?: string
}

export const HotButton = ({ children, keyCode, disabled, onClick, ...restProps }: HotButtonProps): JSX.Element => {
  return (
    <Button onClick={onClick} variant="outlined" disabled={disabled} {...restProps}>
      {children}
      {keyCode && <KeyHint keyCode={keyCode} onPress={onClick ? onClick : noop} disabled={disabled} />}
    </Button>
  )
}
