import { times } from 'lodash'
import { atom } from 'recoil'

export const defaultState = {
  // state version
  version: 8,

  // edit mode
  // mode: 'default' as 'blackout' | 'default' | 'edit' | 'clock' | 'scoring' | 'feature',

  // presentation views
  presenting: {
    scoreboard: false,
    introducingLeft: false,
    introducingRight: false,
    judging: false,
    timer: false,
    slide: false,
    typing: false,
  },

  // teams
  left: {
    name: 'Team A',
    fontFamily: 'sans-serif',
    fontSize: 20,
    score: 0,
    textColor: '#ffffff',
    backgroundColor: '#888800',
    notes: '',
    judging: 'enabled' as 'enabled' | 'active' | 'disabled',
  },
  right: {
    name: 'Team B',
    fontFamily: 'sans-serif',
    fontSize: 20,
    score: 0,
    textColor: '#ffffff',
    backgroundColor: '#008888',
    notes: '',
    judging: 'enabled' as 'enabled' | 'active' | 'disabled',
  },

  judging: {
    celebration: false,
    scores: [] as number[],
    applying: false,
  },

  celebration: {
    selectedIndex: 0,
  },

  scoreboard: {
    finale: false,
  },

  timer: {
    fullSeconds: 30,
    seconds: 30,
    running: false,
    mode: 'overtime' as 'overtime' | 'game',
  },

  penalties: {
    timer: 0,
    content: 0,
  },

  slides: {
    selectedId: null as null | string,
  },
}

export const stateAtom = atom({ key: 'store', default: defaultState })

export const presentationAtom = atom({
  key: 'presentation',
  default: {
    height: 0,
    width: 0,
    connected: false,
  },
})

export const slidesAtom = atom({
  key: 'slides',
  default: times(10).map((i) => ({
    id: String(i),
    keyCode: '',
    label: '',
    url: '',
  })),
})
