/** @jsxImportSource @emotion/react */
import React from 'react'

export interface BackgroundProps {
  colorLeft: string
  colorRight: string
  colorSeparator: string
}

export const Background = ({ colorLeft, colorRight, colorSeparator }: BackgroundProps): JSX.Element => {
  // code
  return (
    <div css={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
      <svg viewBox="0 0 400 300" height="100%" width="100%" preserveAspectRatio="none">
        <path
          d={`
          M 0,0
          L 400,0
          L 400,300
          L 0,300
          Z
        `}
          fill={colorRight}
        />
        <path
          d={`
          M 0,0
          L 400,0
          L 400,100
          L 300,100
          C 200,100 200,200 100,200 
          L 0,200
          Z
        `}
          fill={colorLeft}
        />
        <path
          d={`
          M 400,100
          L 300,100
          C 200,100 200,200 100,200 
          L 0,200
        `}
          fill="none"
          stroke={colorSeparator}
          strokeWidth={10}
        />
      </svg>
    </div>
  )
}
