/** @jsxImportSource @emotion/react */
import React, { useRef } from 'react'
import { useViewSize } from '../lib/useViewSize'

export interface TeamNameProps {
  // isEditing: boolean
  className?: string
  fontFamily: string
  fontSize: number
  value: string
  // onChange: (newValue: string) => void
  color: string
}

export const TeamName = ({
  className,
  fontFamily,
  fontSize,
  // isEditing,
  value,
  // onChange,
  color,
}: TeamNameProps): JSX.Element => {
  const ref = useRef<HTMLInputElement>(null)

  const { vh } = useViewSize()

  return (
    <div
      className={className}
      css={{
        fontFamily,
        fontSize: vh(fontSize / 2),
        color,
        lineHeight: 1.1,
      }}
    >
      {value}
    </div>
  )
}
