/** @jsxImportSource @emotion/react */
import localforage from 'localforage'
import React, { useEffect, useState } from 'react'
import { DefaultValue, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { defaultState, slidesAtom, stateAtom } from '../data'
import { ControlsView } from './ControlsView'
import { PresentingView } from './PresentingView'

localforage.config({
  name: 'tic-scoreboard',
})

export const Main = (): JSX.Element => {
  const [mode, setMode] = useState<null | 'control' | 'presentation' | 'preview'>(null)
  const isControl = mode === 'control'

  const [state, setState] = useRecoilState(stateAtom)
  const setSlides = useSetRecoilState(slidesAtom)

  // key blocking
  useEffect(() => {
    window.addEventListener('keydown', (e) => {
      const target = e.target as any
      if (e.key === ' ' && target?.tagName === 'button') {
        e.stopPropagation()
        e.preventDefault()
        // TODO: doesn't work!
      }
    })
  }, [])

  // routing
  useEffect(() => {
    if (mode) return

    if (window.location.hash === '#preview') {
      setMode('preview')
      return
    }

    if (window.location.hash === '#presentation') {
      setMode('presentation')
      return
    }

    setMode('control')
    
    try {
      const data = window.localStorage.getItem('tic.scoreboard')
      if (!data) return
      const parsed = JSON.parse(data)
      if (parsed.version !== defaultState.version) return
      // console.log('loading state', parsed)
      setState(parsed)
    } catch {
      // do nothing
    }
  }, [])

  // state saving
  useEffect(() => {
    if (!isControl) return
    // console.log('saving state', state)
    window.localStorage.setItem('tic.scoreboard', JSON.stringify(state))
  }, [mode, state])

  // slides loading
  useEffect(() => {
    localforage.getItem('slides').then((slides) => {
      if (slides) setSlides(slides as any) // TODO
    })
  }, [])

  if (!mode) return <div></div>

  if (!isControl) return <PresentingView mode={mode} />

  return <ControlsView />
}
