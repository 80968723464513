/** @jsxImportSource @emotion/react */
import { Box, Card, CssBaseline, IconButton, Stack, TextField, Typography, useTheme } from '@mui/material'
import produce from 'immer'
import { noop, random, remove, uniqueId } from 'lodash'
import React, { useEffect, useReducer } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { broadcast, channel, ChannelMessage, defaultState, presentationAtom, stateAtom } from '../data'
import { Container } from './Container'
import { Preview } from './Preview'
import { KeyHint } from './KeyHint'
import { TimerControls } from './TimerControls'
import { ScoreboardControls } from './ScoreboardControls'
import { HotButton } from './HotButton'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from '../lib/theme'
import { Button } from './Button'
import { SlidesControls } from './SlidesControls'
import HelpIcon from '@mui/icons-material/Help'
import { celebrationKeys } from './Judging'
import { MiscControls } from './MiscControls'

export interface ControlsViewProps {}

export const ControlsView = (props: ControlsViewProps): JSX.Element => {
  const [state, setState] = useRecoilState(stateAtom)
  const [presentation, setPresentation] = useRecoilState(presentationAtom)

  // ping
  useEffect(() => {
    broadcast({ type: 'ping', payload: undefined })
  }, [])

  useEffect(() => {
    // listener
    channel.onmessage = ({ data }: MessageEvent<ChannelMessage>) => {
      const { type, payload } = data
      switch (type) {
        case 'presentationDimensions': {
          setPresentation({ ...payload, connected: true })
          broadcast({ type: 'fields', payload: state })
          break
        }
      }
    }
    return () => {
      channel.onmessage = null
    }
  })

  useEffect(() => {
    //  broadcaster
    broadcast({ type: 'fields', payload: state })
  }, [state])

  const handleOpenPresentation = () => {
    const presentationWindow = window.open(`${window.location.pathname}#presentation`, 'presentation')
    const loop = setInterval(() => {
      if (presentationWindow?.closed) {
        clearInterval(loop)
        setPresentation(
          produce((draft) => {
            draft.connected = false
          })
        )
      }
    }, 1000)
  }

  // RESET

  const handleReset = () => {
    setState(
      produce(defaultState, (draft) => {
        draft.celebration.selectedIndex = random(celebrationKeys.length - 1)
      })
    )
  }

  // RENDER

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Stack sx={{ p: 1, position: 'relative', height: '100vh', overflow: 'hidden' }}>
        <Card sx={{ p: 1, m: 0 }} css={{ flexShrink: 0 }}>
          <Stack direction="row" alignItems="center">
            <HotButton
              keyCode="esc"
              onClick={() => {
                setState(
                  produce((draft) => {
                    Object.keys(draft.presenting).forEach((key) => {
                      draft.presenting[key as keyof typeof draft.presenting] = false
                    })
                  })
                )
              }}
            >
              Blackout
            </HotButton>
            <Button onClick={handleOpenPresentation} disabled={presentation.connected}>
              Open Projector Window
            </Button>
            <Button onClick={handleReset}>Reset</Button>
            <div css={{ flex: 1 }} />
            <IconButton
              onClick={() => {
                window.open(
                  'https://docs.google.com/document/d/1360feDhThOcsOZ8dGeQyzoTP5EK22o3ZiidKTlPjF2s/edit?usp=sharing',
                  '__blank'
                )
              }}
            >
              <HelpIcon />
            </IconButton>
            <div css={{ color: 'grey', textAlign: 'center', textTransform: 'uppercase', fontSize: '80%' }}>
              <span css={{ fontWeight: 'bold' }}>TIC Scoreboard</span>
              <br />
              by Tybot Laboratories
            </div>
          </Stack>
        </Card>
        <ScoreboardControls />
        <TimerControls />
        <SlidesControls />
        <MiscControls />
        <Container css={{ flex: 1, display: 'flex', justifyContent: 'center', position: 'relative' }}>
          {!presentation.connected && (
            <Box sx={{ position: 'absolute', color: 'red', left: 0, zIndex: 200, background: '#00000088' }}>
              NOT CONNECTED TO PROJECTOR
            </Box>
          )}
          <Preview />
        </Container>
      </Stack>
    </ThemeProvider>
  )
}
