/* eslint-disable jsx-a11y/alt-text */
/** @jsxImportSource @emotion/react */
import { Card, Stack } from '@mui/material'
import React from 'react'
import { HotButton } from './HotButton'
import { useRecoilState } from 'recoil'
import { stateAtom } from '../data'
import produce from 'immer'
import { Button } from './Button'

export const MiscControls: React.FC = () => {
  const [state, setState] = useRecoilState(stateAtom)

  return (
    <>
      <Card css={{ flexShrink: 0 }}>
        <Stack direction="row" alignItems="center">
          <Button disabled variant="text">
            Misc
          </Button>
          <HotButton
            variant={state.presenting.typing ? 'contained' : 'outlined'}
            onClick={() => {
              setState(
                produce((draft) => {
                  draft.presenting.typing = !draft.presenting.typing
                })
              )
            }}
          >
            Typing
          </HotButton>
        </Stack>
      </Card>
    </>
  )
}
