/** @jsxImportSource @emotion/react */
import produce from 'immer'

import React, { useEffect, useState } from 'react'
import { config } from '../config'

export interface ScoreProps {
  className?: string
  value: number
  color: string
}

const Digit = ({ value }: { value: number }) => (
  <span
    css={{
      display: 'inline-block',
      background: '#00000088',
      margin: '0 0.025em',
      padding: '0.05em 0.1em',
      position: 'relative',
      overflow: 'hidden',
      boxSizing: 'border-box',
    }}
  >
    <div css={{ padding: 0, position: 'relative' }}>
      <div css={{ position: 'absolute', paddingTop: 0, top: `-${value * 100}%`, lineHeight: 1 }}>
        <div>0</div>
        <div>1</div>
        <div>2</div>
        <div>3</div>
        <div>4</div>
        <div>5</div>
        <div>6</div>
        <div>7</div>
        <div>8</div>
        <div>9</div>
        <div>0</div>
      </div>
      <span css={{ visibility: 'hidden' }}>0</span>
    </div>
  </span>
)

export const Score = ({ className, value, color }: ScoreProps): JSX.Element => {
  // const [floatingValue, setFloatingValue] = useState(value)

  useEffect(() => {
    // if (!config.scoringMode) {
    //   setFloatingValue(value)
    //   return
    // }
    // if (value === floatingValue) return
    // const startValue = floatingValue
    // const startTime = Date.now()
    // const animate = () => {
    //   const now = Date.now()
    //   const absoluteProgress = Math.min(1, (now - startTime) / SCORE_ANIMATION_DURATION)
    //   const easedProgress = ease.easeInOutQuad(absoluteProgress)
    //   setFloatingValue(startValue + (value - startValue) * easedProgress)
    //   if (absoluteProgress < 1) requestAnimationFrame(animate)
    // }
    // animate()
  }, [value])

  const digits = (() => {
    const ones = value % 10
    const tens = (Math.floor(value / 10) % 10) + Math.max(ones - 9, 0)
    const hundreds = (Math.floor(value / 100) % 10) + Math.max(tens - 9, 0)
    return [ones, tens, hundreds]
  })()

  return (
    <div
      className={className}
      css={{ fontSize: `${config.scoreFontSize}vw`, lineHeight: 1, fontFamily: config.numberFontFamily, color }}
    >
      {!!digits[2] && <Digit value={digits[2]} />}
      <Digit value={digits[1]} />
      <Digit value={digits[0]} />
    </div>
  )
}
