/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef } from 'react'
import { stateAtom } from '../data'
import { useRecoilValue } from 'recoil'

interface TypingProps {
  mode: 'presentation' | 'preview'
}

export const Typing: React.FC<TypingProps> = ({ mode }) => {
  const state = useRecoilValue(stateAtom)
  const ref = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (!ref.current) return
    ref.current.value = ''
  }, [state.presenting.typing])

  useEffect(() => {
    ref.current?.focus()
  }, [])

  if (mode === 'presentation')
    return (
      <textarea
        ref={ref}
        css={{
          position: 'absolute',
          top: 0,
          left: 0,
          margin: '10px',
          height: '100%',
          width: '100%',
          background: 'black',
          color: 'white',
          fontSize: '7vh',
          border: 'none',
          outline: 'none',
          fontFamily: 'monospace',
          boxSizing: 'border-box',
        }}
        spellCheck={false}
      />
    )

  return (
    <div
      css={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: '10%',
      }}
    >
      <div css={{ flexShrink: 1, width: '50%', fontSize: '3vh', color: 'yellow' }}>
        Typing Mode engaged, click on projector window and ensure cursor appears there. Do not touch this window until
        typing is over!
      </div>
    </div>
  )
}
