/** @jsxImportSource @emotion/react */
import { ButtonProps as MuiButtonProps, Button as MuiButton } from '@mui/material'
import React from 'react'

export type ButtonProps = MuiButtonProps

export const Button = (props: ButtonProps): JSX.Element => {
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    if (!props.onClick) return
    props.onClick(e)
    ;(e.target as any).blur()
  }
  return <MuiButton {...props} onClick={handleClick} />
}
