/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { config } from '../config'
import { useRecoilValue } from 'recoil'
import { stateAtom } from '../data'
import { useViewSize } from '../lib/useViewSize'
import { useHotkeys } from 'react-hotkeys-hook'
dayjs.extend(duration)

export const Timer = (): JSX.Element => {
  const {
    timer: { seconds: timerSeconds, mode },
    penalties: { timer: penalties },
  } = useRecoilValue(stateAtom)

  const minutes = Math.floor(timerSeconds / 60)
  const seconds = timerSeconds - minutes * 60
  const duration = dayjs.duration({ seconds, minutes })

  const { vmin } = useViewSize()

  return (
    <div
      css={{
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        background: mode === 'overtime' ? '#aa0000' : 'black',
        color: 'white',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: config.numberFontFamily,
      }}
    >
      <div css={{ fontSize: vmin(7), visibility: 'hidden' }}>{'I'}</div>
      {mode === 'overtime' || timerSeconds ? (
        <div css={{ fontSize: vmin(30), fontWeight: 'bold' }}>{duration.format('m:ss')}</div>
      ) : (
        <div css={{ fontSize: vmin(15), fontWeight: 'bold' }}>TIME'S UP!</div>
      )}
      <div css={{ fontSize: vmin(7), visibility: penalties && mode === 'overtime' ? 'visible' : 'hidden' }}>
        {penalties} overtime{penalties > 1 && 's'}!
      </div>
    </div>
  )
}
