/* eslint-disable jsx-a11y/alt-text */
/** @jsxImportSource @emotion/react */
import {
  Box,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import DeleteIcon from '@mui/icons-material/Delete'
import React, { useState } from 'react'
import { HotButton } from './HotButton'
import { useRecoilState } from 'recoil'
import { broadcast, slidesAtom, stateAtom } from '../data'
import produce from 'immer'
import { Button } from './Button'
import { uniqueId, without } from 'lodash'
import localforage from 'localforage'
import ReactImageUploading, { ImageListType } from 'react-images-uploading'
import { defaultPresentation } from '../config'

export interface SlidesControlsProps {}

const unreservedKeyCodes = without(
  'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split(''),
  ...[
    'A', // intro left
    'B', // intro right
    'C', // reserved for celebration? camera?
    'F', // reserved for finale scoring
    'J', // judging
    'S', // scoreboard
    'T', // timer
    'X', // swap left/right
    'Y', // yellow flag
  ]
)

export const SlidesControls = (props: SlidesControlsProps): JSX.Element => {
  const [state, setState] = useRecoilState(stateAtom)
  const [slides, setSlides] = useRecoilState(slidesAtom)
  const [isSettingsOpen, setIsSettingsOpen] = useState(false)

  const handleSave = () => {
    localforage.setItem('slides', slides).then(() => {
      setIsSettingsOpen(false)
      broadcast({ type: 'refreshSlides', payload: undefined })
      setState(
        produce((draft) => {
          draft.presenting.slide = false
          draft.slides.selectedId = null
        })
      )
    })
  }

  const usedKeyCodes = slides.filter(({ keyCode }) => keyCode).map(({ keyCode }) => keyCode)

  return (
    <>
      <Card css={{ flexShrink: 0 }}>
        <Stack direction="row" alignItems="center">
          <Button disabled variant="text">
            Slides
          </Button>
          {slides
            .filter(({ url }) => url)
            .map(({ id, keyCode, label }) => (
              <HotButton
                variant={state.presenting.slide && state.slides.selectedId === id ? 'contained' : 'outlined'}
                key={id}
                keyCode={keyCode}
                onClick={() => {
                  setState(
                    produce((draft) => {
                      if (draft.presenting.slide && draft.slides.selectedId === id) {
                        draft.slides.selectedId = null
                        draft.presenting.slide = false
                      } else {
                        draft.slides.selectedId = id
                        draft.presenting.slide = true
                      }
                    })
                  )
                }}
              >
                <span css={{ fontSize: '75%' }}>{label || 'Unlabeled Slide'}</span>
              </HotButton>
            ))}
          <Box flex={1} />
          <Box>
            <IconButton
              onClick={() => {
                setIsSettingsOpen(true)
              }}
            >
              <SettingsIcon />
            </IconButton>
          </Box>
        </Stack>
      </Card>
      <Dialog open={isSettingsOpen} fullScreen>
        <DialogTitle>Slides</DialogTitle>
        <DialogContent>
          <Stack>
            {slides.map(({ id, keyCode, label, url }, slideIndex) => {
              const selectableKeyCodes = unreservedKeyCodes.filter(
                (unreservedKeyCode) => keyCode === unreservedKeyCode || !usedKeyCodes.includes(unreservedKeyCode)
              )
              return (
                <Stack direction="row" key={id}>
                  <TextField
                    label="Label"
                    value={label}
                    onChange={(e) => {
                      setSlides(
                        produce((draft) => {
                          draft[slideIndex].label = e.target.value
                        })
                      )
                    }}
                  />
                  <FormControl>
                    <InputLabel>Key</InputLabel>
                    <Select
                      label="Key"
                      sx={{ width: '5em' }}
                      value={keyCode}
                      onChange={(e) => {
                        setSlides(
                          produce((draft) => {
                            draft[slideIndex].keyCode = e.target.value
                          })
                        )
                      }}
                    >
                      <MenuItem value={''}>
                        <em>None</em>
                      </MenuItem>
                      {selectableKeyCodes.map((keyCode) => (
                        <MenuItem key={keyCode} value={keyCode}>
                          {keyCode}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <ReactImageUploading
                    value={[{ dataUrl: url }]}
                    onChange={(imageList: ImageListType, addUpdateIndex: any) => {
                      console.log(imageList, addUpdateIndex)
                      const newUrl = imageList[0].dataUrl
                      if (!newUrl) throw new Error()
                      setSlides(
                        produce((draft) => {
                          draft[slideIndex].url = newUrl
                        })
                      )
                    }}
                    dataURLKey="dataUrl"
                  >
                    {
                      ({ imageList, onImageUpload, onImageUpdate, onImageRemove, isDragging, dragProps }) => {
                        const image = imageList[0]
                        const { dataUrl } = image
                        if (!image) return null
                        return (
                          <>
                            <div
                              key={slideIndex}
                              css={{
                                background: dataUrl ? 'black' : 'transparent',
                                border: isDragging ? '1px solid yellow' : '1px solid black',
                                height: 100 / defaultPresentation.aspectRatio,
                                width: 100,
                                cursor: 'pointer',
                              }}
                              onClick={onImageUpload}
                              {...dragProps}
                            >
                              {dataUrl && (
                                <img src={dataUrl} css={{ height: '100%', width: '100%', objectFit: 'contain' }} />
                              )}
                            </div>
                            <div>
                              <IconButton
                                disabled={!dataUrl}
                                size="large"
                                onClick={() => {
                                  setSlides(
                                    produce((draft) => {
                                      draft[slideIndex].label = ''
                                      draft[slideIndex].keyCode = ''
                                      draft[slideIndex].url = ''
                                    })
                                  )
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          </>
                        )
                      }
                      // <>
                      //   <Button
                      //     style={isDragging ? { color: 'red' } : undefined}
                      //     onClick={onImageUpload}
                      //     {...dragProps}
                      //   >
                      //     Click to Select or Drag Here
                      //   </Button>
                      //   &nbsp;
                      //   {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                      //   {imageList.map((image, index) => (
                      //     <div key={index}>
                      //       <img src={image['dataUrl']} alt="" height="100" />
                      //       {/* <button onClick={() => onImageRemove(index)}>Remove</button> */}
                      //     </div>
                      //   ))}
                      // </>
                    }
                  </ReactImageUploading>
                </Stack>
              )
            })}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave}>Save and Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
