export const config = {
  // values in width %
  nameX: 8,
  nameY: 0,
  nameHeight: 30,
  nameWidth: 84,
  scoreX: 8,
  scoreY: 35,
  scoreHeight: 30,
  controlsX: 30,
  controlsY: 30,
  scoreFontSize: 14,
  judgeScoreFontSize: 20,
  timerFontSize: 25,
  timerOvertimesFontSize: 5,
  scoringPenaltiesFontSize: 3,

  // other
  timerSeconds: 30,
  numberFontFamily: '"Helvetica Neue", Helvetica, monospace',
  scoringMode: false, // false is "classic" mode
  judgeCount: 3,
}

export const defaultPresentation = {
  width: 1280,
  height: 800,
  aspectRatio: 1280 / 800,
}
