/** @jsxImportSource @emotion/react */
import { TextField, TextFieldProps } from '@mui/material'
import React, { useEffect, useRef } from 'react'

export const NumberField = ({ onChange, ...restProps }: TextFieldProps): JSX.Element => {
  const ref = useRef<HTMLInputElement>(null)

  useEffect(() => {
    ref.current?.addEventListener('wheel', (e) => e.preventDefault(), { passive: false })
  }, [])

  return <TextField {...restProps} onChange={onChange} ref={ref} type="number" />
}
