import React, { useContext, useMemo } from 'react'

export const ViewSizeContext = React.createContext({
  width: 0,
  height: 0,
})

export const useViewSize = () => {
  const context = useContext(ViewSizeContext)
  return useMemo(() => {
    const { width, height } = context
    const vw = (units: number) => units / 100 * width
    const vh = (units: number) => units / 100 * height
    const [vmin, vmax] = width > height ? [vh, vw] : [vw, vh]
    return { vw, vh, vmin, vmax }
  }, [context.width, context.height])
}
