/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from 'react'

import { ViewSizeContext } from '../lib/useViewSize'

export interface ContainerProps {
  className?: string
  children: React.ReactNode
}

export const Container = ({ className, children }: ContainerProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null)
  const [viewSize, setViewSize] = useState({ width: 0, height: 0 })
  const [render, setRender] = useState({})

  useEffect(() => {
    const handleResize = () => {
      setRender({})
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    if (!ref.current) throw new Error()
    const { clientHeight: height, clientWidth: width } = ref.current
    setViewSize({ height, width })
  }, [ref.current?.clientHeight, ref.current?.clientWidth])

  return (
    <div ref={ref} className={className} css={{ position: 'relative' }}>
      {viewSize.width && <ViewSizeContext.Provider value={viewSize}>{children}</ViewSizeContext.Provider>}
    </div>
  )
}
