/** @jsxImportSource @emotion/react */
import localforage from 'localforage'
import React, { useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { broadcast, channel, ChannelMessage, slidesAtom, stateAtom } from '../data'
import { Container } from './Container'
import { Introducing } from './Introducing'
import { Judging } from './Judging'
import { Scoreboard } from './Scoreboard'
import { Slide } from './Slide'
import { Timer } from './Timer'
import { Typing } from './Typing'

export interface PresentingViewProps {
  mode: 'presentation' | 'preview'
}

const FullViewContainer = ({ children }: { children: React.ReactNode }) => (
  <Container css={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }}>{children}</Container>
)

export const PresentingView = ({ mode }: PresentingViewProps): JSX.Element => {
  const [state, setState] = useRecoilState(stateAtom)
  const setSlides = useSetRecoilState(slidesAtom)

  const sendDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window
    broadcast({ type: 'presentationDimensions', payload: { width, height } })
  }

  useEffect(() => {
    // listener
    channel.onmessage = ({ data }: MessageEvent<ChannelMessage>) => {
      const { type, payload } = data
      switch (type) {
        case 'fields': {
          setState(payload)
          break
        }
        case 'ping': {
          sendDimensions()
          break
        }
        case 'refreshSlides': {
          localforage.getItem('slides').then((slides) => {
            if (slides) setSlides(slides as any) // TODO
          })
          break
        }
      }
    }
    return () => {
      channel.onmessage = null
    }
  }, [])

  useEffect(() => {
    if (mode === 'preview') return

    // broadcaster
    window.addEventListener('resize', sendDimensions)
    sendDimensions()
    return () => {
      window.removeEventListener('resize', sendDimensions)
    }
  }, [mode])

  return (
    <div
      css={{
        position: 'relative',
        height: '100vh',
        width: '100vw',
        background: 'black',
        cursor: 'none',
        overflow: 'hidden',
      }}
    >
      <FullViewContainer>
        <CSSTransition in={state.presenting.scoreboard} timeout={200} classNames="fade" mountOnEnter>
          <div>
            <Scoreboard />
          </div>
        </CSSTransition>
        <Introducing in={state.presenting.introducingLeft} side="left" />
        <Introducing in={state.presenting.introducingRight} side="right" />
        <CSSTransition in={state.presenting.judging} timeout={200} classNames="fade" mountOnEnter>
          <div>
            <Judging />
          </div>
        </CSSTransition>
        <CSSTransition in={state.presenting.timer} timeout={200} classNames="fade" mountOnEnter>
          <div>
            <Timer />
          </div>
        </CSSTransition>
        <CSSTransition in={state.presenting.slide} timeout={200} classNames="fade" mountOnEnter>
          <div>
            <Slide />
          </div>
        </CSSTransition>
        <CSSTransition in={state.presenting.typing} timeout={200} classNames="fade" mountOnEnter>
          <div>
            <Typing mode={mode} />
          </div>
        </CSSTransition>
      </FullViewContainer>
    </div>
  )
}
